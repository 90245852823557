import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Commands = () => {
  return (
    <Layout>
      <SEO title="Music" />
      <h1>Commands</h1>
      <div>
        <p>
          Here lies a list of useful commands I've come across. I'm sure I'll
          need at least some of them again in the future. This is where they'll
          stay for now.
        </p>

        <hr />

        <div>
          <div>sudo hdparm -Tt /dev/sda</div>
          <div>Check hard disk performance.</div>
          <hr />
        </div>

        <div>
          <div>df -h</div>
          <div>Display disk space usage for all mounted drives.</div>
          <hr />
        </div>

        <div>
          <div>uname -a</div>
          <div>
            Print out Linux kernel version and architecture as well as other
            useful system information.
          </div>
          <hr />
        </div>

        <div>
          <div>cat /proc/sys/vm/swappiness</div>
          <div>Print systems swappiness value.</div>
          <hr />
        </div>

        <div>
          <div>xset dpms force off</div>
          <div>
            Turn off the monitor. Any keypress or mouse action turns it on
            again.
          </div>
          <hr />
        </div>

        <div>
          <div>xev</div>
          <div>
            Find out the code of a button on the keyboard. It also prints out
            all X events.
          </div>
          <hr />
        </div>

        <div>
          <div>netstat -nr</div>
          <div>Print the network gateway.</div>
          <hr />
        </div>

        <div>
          <div>cat /proc/cpuinfo</div>
          <div>Print processor information.</div>
          <hr />
        </div>

        <div>
          <div>unzip -t &lt;archive.zip&gt;</div>
          <div>Test and display zip contents without extracting any files.</div>
          <hr />
        </div>

        <div>
          <div>tar czf &lt;backup.tar.gz&gt; &lt;path_to_archive&gt;</div>
          <div>Create tar.gz backup archive.</div>
          <hr />
        </div>

        <div>
          <div>tar -xzf &lt;backup.tar.gz&gt;</div>
          <div>Extract a tar.gz backup archive.</div>
          <hr />
        </div>

        <div>
          <div>sudo -i</div>
          <div>Log in as the root user.</div>
          <hr />
        </div>

        <div>
          <div>du -hs &lt;path&gt;</div>
          <div>
            Print the size of a directory. Leave out &lt;path&gt; to use the
            current directory.
          </div>
          <hr />
        </div>

        <div>
          <div>crontab -e</div>
          <div>Edit tasks run by cron (task scheduling).</div>
          <hr />
        </div>

        <div>
          <div>sshd -v</div>
          <div>See the version number of the ssh server.</div>
          <hr />
        </div>

        <div>
          <div>
            tar -cj &lt;path&gt; | openssl des3 -salt &gt;
            &lt;encrypted_file&gt;
          </div>
          <div>Encrypt a file or directory with bzip2 compression.</div>
          <hr />
        </div>

        <div>
          <div>
            cat &lt;encrypted_file&gt; | openssl des3 -d -salt | tar -xvj
          </div>
          <div>
            Decrypt a encrypted file. It will be saved in the current directory.
          </div>
          <hr />
        </div>

        <div>
          <div>which &lt;executable&gt;</div>
          <div>Print the path to the installed executable file.</div>
          <hr />
        </div>

        <div>
          <div>/sbin/mdadm --detail /dev/md0</div>
          <div>Print the current RAID status.</div>
          <hr />
        </div>

        <div>
          <div>tar -ztvf &lt;backup.tar.gz&gt;</div>
          <div>List the contents of tar.gz archive without extracting.</div>
          <hr />
        </div>

        <div>
          <div>watch -t 0.5 &lt;command&gt;</div>
          <div>Run and print &lt;command&gt; every 0.5 seconds.</div>
          <hr />
        </div>

        <div>
          <div>sudo usermod -s /bin/false &lt;user&gt;</div>
          <div>
            Deny &lt;user&gt; shell access by setting their shell to false.
          </div>
          <hr />
        </div>

        <div>
          <div>exec bash</div>
          <div>
            Reload the current bash process to load any configuration changes.
          </div>
          <hr />
        </div>

        <div>
          <div>cd -</div>
          <div>Go back to previous directory.</div>
          <hr />
        </div>

        <div>
          <div>sudo dmidecode -t 2</div>
          <div>Display motherboard info.</div>
          <hr />
        </div>

        <div>
          <div>sudo dpkg -i *.deb</div>
          <div>Install all .deb in the current directory.</div>
          <hr />
        </div>

        <div>
          <div>
            scp -r &lt;source_path&gt;
            &lt;user&gt;@&lt;ip&gt;:&lt;remote_path&gt;
          </div>
          <div>Push file/folder to a remote server.</div>
          <hr />
        </div>

        <div>
          <div>sudo dpkg-reconfigure tzdata</div>
          <div>Change the timezone on a deb based system.</div>
          <hr />
        </div>

        <div>
          <div>ssh-keygen -H -F &lt;hostname&gt;</div>
          <div>Search for &lt;hostname&gt; in the known_hosts file.</div>
          <hr />
        </div>

        <div>
          <div>
            tar -czf &lt;backup.tar.gz&gt; --exclude=&lt;PATTERN1&gt;
            --exclude=&lt;PATTERN2&gt; &lt;path&gt;
          </div>
          <div>
            Create a tar.gz backup, excluding &lt;PATTERN1&gt; and
            &lt;PATTERN2&gt;.
          </div>
          <hr />
        </div>

        <div>
          <div>
            find . -name &lt;pattern&gt; -print | xargs sed -i
            's/&lt;foo&gt;/&lt;bar&gt;/g'
          </div>
          <div>
            Find &lt;foo&gt; and replace it with &lt;bar&gt; across multiple
            files that match the &lt;pattern&gt;. e.g. *.txt
          </div>
          <hr />
        </div>

        <div>
          <div>lsof -n -p &lt;pid&gt;</div>
          <div>See the files that process &lt;pid&gt; has open.</div>
          <hr />
        </div>

        <div>
          <div>mysql -u root -p -h localhost database_name &lt; backup.sql</div>
          <div>Import backup.sql into database as the mysql root user.</div>
          <hr />
        </div>

        <div>
          <div>{`for f in *.wav; do ffmpeg -i "$f" -ab 320k "\${f%.wav}.mp3"; done`}</div>
          <div>
            Encode all .wav files in the current directory to 320Kbps mp3s.
          </div>
          <hr />
        </div>

        <div>
          <div>sudo alsactl store</div>
          <div>
            Save the current driver state for the selected soundcard to the
            configuration file.
          </div>
          <hr />
        </div>

        <div>
          <div>lsof +c 0 /dev/snd/pcm* /dev/dsp*</div>
          <div>See which processes have taken hold of your sound devices.</div>
          <hr />
        </div>

        <div>
          <div>date</div>
          <div>Print the current time and date.</div>
          <hr />
        </div>

        <div>
          <div>dd if=/dev/device_name of=image.img bs=4M</div>
          <div>
            Make an exact copy of a drive, such as a hard drive or a SD card
            from a Raspberry Pi.
          </div>
          <hr />
        </div>

        <div>
          <div>sudo !!</div>
          <div>Run the previous command with sudo.</div>
          <hr />
        </div>

        <div>
          <div>mogrify -format png *.bmp</div>
          <div>
            Convert images from one format to another preserving file names.
            Requires imagemagick.
          </div>
          <hr />
        </div>

        <div>
          <div>{`for file in *.wav; do lame -m m --preset standard "$file" "\${file%.wav}.mp3"; done`}</div>
          <div>
            Convert mono wav files, in the current directory, to mono mp3's at
            decent quality. and with a small file size.
          </div>
          <hr />
        </div>

        <div>
          <div>
            mongoimport -d &lt;database&gt; -c &lt;collection&gt; --type csv
            --file &lt;data.csv&gt; --headerline
          </div>
          <div>Import a csv file into mongodb.</div>
          <hr />
        </div>

        <div>
          <div>ssh-copy-id &lt;user&gt;@&lt;ip&gt;</div>
          <div>Copy a ssh public key to a server.</div>
          <hr />
        </div>

        <div>
          <div>htpasswd &lt;password_file&gt; &lt;username&gt;</div>
          <div>Append user to &lt;password_file&gt; for apache2.</div>
          <hr />
        </div>

        <div>
          <div>mogrify -resize &lt;width&gt;x&lt;height&gt; *.jpg</div>
          <div>Resize all jpg images in folder. They will be overwritten.</div>
          <hr />
        </div>

        <div>
          <div>{`for f in *.flac; do ffmpeg -i "$f" -q:a 2 "\${f%.flac}.mp3"; done`}</div>
          <div>
            Convert all flac files in the current directory to mp3, with a
            variable bit rate of 170-210 kbps.
          </div>
          <hr />
        </div>

        <div>
          <div>ln -s &lt;source_path&gt; &lt;link_path&gt;</div>
          <div>Create a symbolic link to a file or folder.</div>
          <hr />
        </div>

        <div>
          <div>
            mongodump --db &lt;database&gt; --collection &lt;collection&gt;
          </div>
          <div>Export a mongodb database in a importable mongodb format.</div>
          <hr />
        </div>

        <div>
          <div>shntool split -f album.cue album.wav</div>
          <div>
            Split an album, that is contained in one wav file, into separate
            tracks that are described in a cue file. Requires shntool.
          </div>
          <hr />
        </div>

        <div>
          <div>{`for f in *.png; do convert "$f" -resize &lt;width&gt;x&lt;height&gt; "\${f%.png}.jpg"; done`}</div>
          <div>
            Convert and resize all png images in the current directory to jpg.
            Requires imagemagick.
          </div>
          <hr />
        </div>

        <div>
          <div>find . -mtime 0</div>
          <div>
            Recursively find files that have been modified more recently than 24
            hours ago.
          </div>
          <hr />
        </div>

        <div>
          <div>dig +short myip.opendns.com @resolver1.opendns.com</div>
          <div>
            Print the external/web/global IP address of the current machine.
          </div>
          <hr />
        </div>

        <div>
          <div>rubberband -T 50:100 in.wav out.wav</div>
          <div>Speed up a wav file from 50bpm to 100bpm.</div>
          <hr />
        </div>

        <div>
          <div>{`for f in *.wav; do rubberband -T 85:90 "$f" "\${f%.wav}_stretched.wav"; done`}</div>
          <div>
            Stretch all wav files in the current directory from 85bpm to 90bpm.
          </div>
          <hr />
        </div>

        <div>
          <div>for file in *.wav; do oggenc -q 6 "$file"; done</div>
          <div>
            Convert all wav files in the current directory to Ogg Vorbis.
          </div>
          <hr />
        </div>

        <div>
          <div>
            mongorestore -h 127.0.0.1 --collection &lt;collection&gt; --db
            &lt;database&gt; &lt;collection.bson&gt;
          </div>
          <div>Restore/import a mongodb collection from a mongodump.</div>
          <hr />
        </div>

        <div>
          <div>
            sudo nmap -sP &lt;broadcast_address&gt;/&lt;shorthand_subnet&gt;
          </div>
          <div>Show all nodes on the network.</div>
          <hr />
        </div>

        <div>
          <div>sudo nginx -c /etc/nginx/nginx.conf -t</div>
          <div>Check and test current nginx configuration.</div>
          <hr />
        </div>

        <div>
          <div>ssh -ND 9999 &lt;user&gt;@&lt;host&gt;</div>
          <div>
            Set up a SSH tunnel to browse the web using &lt;host&gt; as a proxy
            server.
          </div>
          <hr />
        </div>

        <div>
          <div>sudo blkid</div>
          <div>
            Display the UUID and filesystem for all block devices (drives)
            attached to the machine.
          </div>
          <hr />
        </div>

        <div>
          <div>find &lt;path&gt; -iname "&lt;pattern&gt;"</div>
          <div>
            Find all the files in the path (and sub directories, recursively),
            whose file names match the pattern.
          </div>
          <hr />
        </div>

        <div>
          <div>
            find &lt;path&gt; -iname "&lt;pattern&gt;" | rename
            's/&lt;find&gt;/&lt;replace&gt;/'
          </div>
          <div>
            Rename multiple files recursively, starting at &lt;path&gt; . If a
            files name contains &lt;find&gt;, replace it with &lt;replace&gt;.
          </div>
          <hr />
        </div>

        <div>
          <div>sudo kpartx -a &lt;disk.img&gt;</div>
          <div>Mount the partitions of a disk image.</div>
          <hr />
        </div>

        <div>
          <div>sudo kill -USR1 $(pgrep ^dd)</div>
          <div>See progress of a running dd command.</div>
          <hr />
        </div>

        <div>
          <div>sudo gpasswd -a user group</div>
          <div>Add a user to a group.</div>
          <hr />
        </div>
      </div>
    </Layout>
  );
};

export default Commands;
